.ValueHovered {
  border: 5px solid black;
}

.ValueNotHovered {
  border: 5px solid transparent;
}

.Value {
  width: 40px;
  text-align: center;
}
