h1 {
  text-align: center;
}

.dataContainer {
  border: 3px solid black;
}

.blockContainer {
  display: flex;
  flex-direction: row;
}

html, body, #root {
  height: 100%;
}

body {
  padding: 10px;
}

.RowHovered {
  background-color: #efefef;
}

.RowNotHovered {
  background-color: transparent;
}

.DataRow {
}
